import styled from "styled-components";
import { colors } from "@utils/colors";
import { querySize } from "@utils/querySize";

export const Container = styled.div`
  background-color: ${colors.secundaryBackground};
  display: flex;
  flex-direction: column;
  padding: 40px 40px 0;
  margin-top: 40px;

  & hr {
    height: 1px;
    background-color: ${colors.borderDrawer};
  }
`;

export const DataContainer = styled.div`
  display: flex;
  padding: 16px 0px 16px 0px;
  align-items: center;
  gap: 16px;
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: ${colors.lightTextColor};

  @media (max-width: ${querySize}) {
    flex-direction: column;
  }

  .icons {
    display: flex;
    gap: 12px;
  }

  a {
    color: ${colors.lightTextColor};
  }

  .small {
    font-family: Inter;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  .title {
    color: black;
    font-family: Work Sans;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${querySize}) {
    flex-direction: column;
  }
`;
