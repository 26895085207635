import styled from "styled-components";
import { colors } from "@utils/colors";

export const Container = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  & .title {
    font-family: Work Sans;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 40px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 16px;
  height: 16px;
  & div {
    position: absolute;
    width: 100%;
    border: 0.5px solid ${colors.iconColor};
  }

  & div[data-first="true"] {
    height: 100%;
    width: 0;
  }
`;

export const Question = styled.div`
  padding: 20px;
  gap: 24px;
  display: grid;
  grid-template-columns: 1fr 0px;
  align-items: center;
  border-bottom: 0.8px solid ${colors.borderDrawer};
  font-family: Work Sans;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  cursor: pointer;

  & .answer{
    transition: 0.3s;
    overflow: hidden;
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.lightTextColor};
    width: 100%;
    height: 0px;
    transaction
  }
  
  &[data-open="true"]{
    color: ${colors.primaryBackground};
    .icon div{
      border: 0.5px solid ${colors.primaryBackground};
    }

    & .answer{
      height: 100px;
    }

   & div[data-first="true"]{
      display: none;
    }
  }
`;
