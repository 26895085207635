import React from "react";
import { Container, ItemsContainer, Line } from "./styled";
import howUse from "@assets/image/howuse.svg";
import phone from "@assets/icons/phone.svg";
import { Button } from "@components/Button";

const items = [
  {
    img: phone,
    title: "Preencha com os seus dados",
    text: "Acesse o Pronto Atendimento Virtual na página de benefícios Natura, inicie seu atendimento e informe seus dados.",
  },
  {
    img: phone,
    title: "Seja atendida de onde estiver",
    text: "Você poderá passar por uma consulta médica Einstein a qualquer hora do dia e da noite.",
  },
  {
    img: phone,
    title: "Receba orientação médica",
    text: "Você receberá orientações médicas, receitas e atestados diretamente no seu computador ou celular, todos com assinatura digital.",
  },
];

export const HowUse: React.FC = () => {
  return (
    <Container id="howuse">
      <div className="title">Como usar?</div>
      <ItemsContainer>
        {items.map(({ img, title, text }, key) => (
          <React.Fragment key={key}>
            <img className="center hidden" src={img} />
            <p className="title">{title}</p>
            {!(key + 1 === items.length) && <Line className="hidden" />}
            <p className="end">{text}</p>
          </React.Fragment>
        ))}
      </ItemsContainer>
      <img className="banner" src={howUse} />
      <Button>Iniciar meu atendimento</Button>
    </Container>
  );
};
