import styled from "styled-components";

export const Container = styled.div`
  padding: 5px;
  display: flex;
  gap: 16px;
  overflow-y: visible;
  overflow-x: auto;
  scrollbar-width: thin;
`;
