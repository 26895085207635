import styled from "styled-components";
import { colors } from "@utils/colors";
import { querySize } from "@utils/querySize";

export const Title = styled.p`
  font-family: Inter;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
`;

export const SubTitle = styled.p`
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.lightTextColor};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 16px;
  padding: 24px;
  min-width: 230px;
  height: 196px;
  @media (max-width: ${querySize}) {
    height: 244px;
    max-height: 244px;
  }

  background-color: ${colors.secundaryBackground};

  & img {
    height: 32px;
    width: 32px;
  }
`;
