import React, {
  ButtonHTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Button as ButtonStyled } from "./styles";
import { Loading, StopLoading } from "@utils/axiosInterceps";

export const Button: React.FC<ButtonHTMLAttributes<any>> = ({
  children,
  ...props
}) => {
  const [loadingState, setLoading] = useState(false);

  const loading = useCallback(() => {
    setLoading(true);
  }, []);

  const stopLoading = useCallback(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    window.addEventListener(Loading, loading);
    window.addEventListener(StopLoading, stopLoading);

    return () => {
      window.removeEventListener(Loading, loading);
      window.removeEventListener(StopLoading, stopLoading);
    };
  }, []);

  return (
    <ButtonStyled type="submit" disabled={loadingState} {...props}>
      {children}
    </ButtonStyled>
  );
};
