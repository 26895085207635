import React from "react";
import { Container } from "./styles";
import { Card } from "@components/Card";
import atendimento from "@assets/icons/atendimento.svg";
import doctor from "@assets/icons/doctor.svg";
import self from "@assets/icons/self.svg";

export const CardGroup: React.FC = () => {
  return (
    <Container>
      <Card
        icon={atendimento}
        title="Atendimento 24h"
        subtitle="Receba atendimento médico a qualquer hora do dia ou da noite."
      />
      <Card
        icon={doctor}
        title="Qualidade Einstein"
        subtitle="Profissionais com padrão Einstein de qualidade prontos para atender você."
      />
      <Card
        icon={self}
        title="Praticidade"
        subtitle="Consultas médicas pelo navegador ou aplicativo, fácil e sem sair de casa."
      />
    </Container>
  );
};
