import styled from "styled-components";
import { colors } from "@utils/colors";
import { querySize } from "@utils/querySize";

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  position: static;
`;

export const Title = styled.div`
  font-family: Work Sans;
  color: ${colors.primary};
  font-weight: 600;
  line-height: 19.2px;
`;

export const Drawer = styled.div`
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.secundaryBackground};
  transition: 0.5s;
  border-bottom: 1px solid ${colors.borderDrawer};

  &[data-open="true"] {
    left: 0;
  }

  @media (max-width: ${querySize}) {
    position: absolute;
    height: 100%;
    width: 66%;
    top: 75px;
    left: -100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  a {
    padding: 12px 24px 12px 24px;
    color: ${colors.drawerColor};
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 75px;
  padding: 12px 50px;
  align-self: center;
  position: relative;

  @media (max-width: ${querySize}) {
    justify-content: center;
  }

  button {
    left: 50px;
    position: absolute;
    @media (min-width: ${querySize}) {
      display: none;
    }
  }

  & .title {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 16px;
  }
`;
