export const colors = {
  primary: "#004F92",
  primaryBackground: "#0076DA",
  drawerColor: "#56798A",
  secundaryBackground: "#F8FAFA",
  ultraLightTextColor: "#7F99A6",
  lightTextColor: "#415B68",
  bannerBackground: "#0059A4",
  borderDrawer: "#D0DADE",
  iconColor: "#405B68",
};
