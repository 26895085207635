import React from "react";
import { Container, ItemsContainer, TitleContainer } from "./styles";
import woman from "@assets/image/banner.svg";

export const Banner: React.FC = () => {
  return (
    <Container id="whenuse">
      <div className="image">
        <img src={woman} />
      </div>
      <div className="text">
        <TitleContainer>
          <p className="title">Quando usar?</p>
          <p>
            Receba orientação médica de qualidade, conforme sua necessidade e
            sempre que você precisar.
          </p>
        </TitleContainer>
        <ItemsContainer>
          <li>Diarreia</li>
          <li>Infecção urinária</li>
          <li>Dor de cabeça</li>
          <li>Febre</li>
          <li>Dor de garganta</li>
          <li>Sinusite</li>
          <li>Dor muscular</li>
          <li>Resfriado</li>
        </ItemsContainer>
      </div>
    </Container>
  );
};
