import styled from "styled-components";
import { querySize } from "@utils/querySize";
import { colors } from "@utils/colors";

export const ItemsContainer = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-template-rows: 1fr;
  align-items: center;

  @media (max-width: ${querySize}) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 20px;
  }

  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.lightTextColor};

  & .center {
    margin: 0 auto;
  }

  & .end {
    @media (min-width: ${querySize}) {
      grid-column-end: 3;
    }
  }

  & .title {
    font-family: Work Sans;
    font-weight: 600;
    font-size: 24px;
    line-height: 28.8px;
    color: black;
  }
`;

export const Line = styled.div.attrs({ className: "line" })`
  width: 0px;
  height: 56px;
  margin: 0 auto;
  border: 0.6px solid ${colors.borderDrawer};
`;

export const Container = styled.div`
  display: grid;
  gap: 20px;  

  grid-template-columns: 1fr 420px;

  justify-items: start;
  @media (max-width: ${querySize}){
    justify-items: stretch;
    grid-template-columns: 1fr;

    & .hidden{
      display: none;
    }

    & img{
      width: 100%;
    }

  }

  & > .title{
    width: 100%;
    font-family: Work Sans;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    @media (min-width: ${querySize}){
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  }

`;
