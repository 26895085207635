import { querySize } from "@utils/querySize";
import styled from "styled-components";

export const Container = styled.form`
  min-height: 100vh;
  position: relative;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 50px 50px 0 50px;

  @media (max-width: ${querySize}) {
    padding: 50px 15px 0 15px;
  }
`;
