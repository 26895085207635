import React from "react";
import { Landing } from "./pages/Landing";
import { EmptyStage } from "./pages/EmpyStage";
import { ViewInterface } from "@present/Presentation/ViewInterface";
import { Presentation } from "@present/Presentation";

export class App extends React.Component implements ViewInterface {
  private presentation = new Presentation(this);

  componentDidMount(): void {
    this.presentation.checkJwt();
  }

  state = {
    loading: true,
    empty: true,
    error: true,
  };

  renderLoading(): void {
    this.setState({ loading: true, empty: false, error: false });
  }
  renderLanding(): void {
    this.setState({ loading: false, empty: false, error: false });
  }
  renderEmpty(): void {
    this.setState({ loading: false, empty: true, error: false });
  }
  renderError(): void {
    this.setState({ loading: false, empty: false, error: true });
  }

  render(): React.ReactNode {
    if (this.state.loading) {
      return <></>;
    }

    if (this.state.error) {
      return <EmptyStage type="error" />;
    }

    if (this.state.empty) {
      return <EmptyStage type="empty" />;
    }

    return <Landing />;
  }
}
