import axios from "axios";

export const Loading = "loadingLanding";
export const StopLoading = "stopLoadingLanding";

const LoadingEvent = new CustomEvent(Loading);
const StopLoadingEvent = new CustomEvent(StopLoading);

axios.interceptors.request.use((e) => {
  window.dispatchEvent(LoadingEvent);

  return e;
});

axios.interceptors.response.use(
  (e) => {
    window.dispatchEvent(StopLoadingEvent);

    return e;
  },
  (e) => {
    window.dispatchEvent(StopLoadingEvent);
    return Promise.reject(e);
  }
);
