import { sessionStorageKey } from "@utils/constants";
import axios from "axios";
import * as jose from "jose";

export class MoveToConecta {
  async execute() {
    const jwt = sessionStorage.getItem(sessionStorageKey) || "";

    const tokenObj = jose.decodeJwt(jwt);

    try {
      const body = new URLSearchParams();

      body.append("apiToken", process.env.REACT_ID_TOKEN as string);
      body.append("idFormulario", process.env.REACT_ID_FORMULARIO as string);
      body.append("idInstituicao", process.env.REACT_ID_INSTITUICAO as string);
      body.append("cab_nome_titular", tokenObj.name as string);
      body.append("cab_email_titular", tokenObj.email as string);
      body.append("cab_idconsultor", tokenObj.external_user_id as string);
      body.append("cab_tokenconsultor", jwt);
      body.append("externo", "true");

      const { data } = await axios.post(
        process.env.REACT_FORM_URL || "",
        body,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      const { linkExterno } = data;
      sessionStorage.clear();
      window.location.replace(linkExterno);
    } catch (e) {
      console.error(e);
    }
  }
}
