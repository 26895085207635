import { colors } from "@utils/colors";
import styled from "styled-components";

export const Button = styled.button`
  background-color: ${colors.primaryBackground};
  color: white;
  padding: 16px;
  border-radius: 24px;

  &:disabled {
    background-color: ${colors.drawerColor};
  }
`;
