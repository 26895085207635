import styled from "styled-components";
import { querySize } from "@utils/querySize";
import { colors } from "@utils/colors";

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;

  & .subtitle {
    font-family: Inter;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: ${colors.ultraLightTextColor};
    paragraph: 8px;

    @media (max-width: ${querySize}) {
      font-weight: 500;
      font-size: 16px;
      line-height: 20.8px;
    }
  }

  & .title {
    font-family: Work Sans;
    font-weight: 400;
    font-size: 64px;
    line-height: 70.4px;
    @media (max-width: ${querySize}) {
      font-weight: 400;
      font-size: 48px;
      line-height: 52.8px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-around;

  @media (min-width: ${querySize}) {
    & img {
      width: 840px;
    }
  }
  @media (max-width: ${querySize}) {
    justify-content: flex-start;
    flex-direction: column-reverse;
  }
`;
