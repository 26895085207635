import styled from "styled-components";
import { colors } from "@utils/colors";
import { querySize } from "@utils/querySize";

export const ItemsContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  color: white;
  font-family: Inter;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: white;
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  & .title {
    font-family: Work Sans;
    font-weight: 600;
    font-size: 32px;
    line-height: 38.4px;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  border-radius: 15px;
  background-color: ${colors.bannerBackground};
  height: 400px;
  position: relative;

  @media (max-width: ${querySize}) {
    justify-content: center;
    align-items: center;
  }
  .image {
    position: absolute;
    top: -57px;
    left: 0;
    @media (max-width: ${querySize}) {
      display: none;
    }
  }
  .text {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 64px;
    width: 50%;
    @media (max-width: ${querySize}) {
      padding: 24px;
      width: 100%;
    }
  }
`;
