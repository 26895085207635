import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import { App } from "./App";

const Component = () => {
  if (process.env.REACT_SERVICE_ENABLED === "true") {
    return <App />;
  }
  return <meta http-equiv="refresh" content="0; url=/" />;
};

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Component,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return <></>;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
