import styled from "styled-components";
import { colors } from "@utils/colors";
import { querySize } from "@utils/querySize";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .padding {
    padding: 50px 50px 0 50px;

    @media (max-width: ${querySize}) {
      padding: 50px 15px 0 15px;
    }
  }
`;

export const Grow = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 50px;

  @media (max-width: ${querySize}) {
    flex-direction: column;
    gap: 50px;
  }

  font-family: Inter;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${colors.ultraLightTextColor};

  .title {
    font-family: Work Sans;
    font-weight: 400;
    font-size: 64px;
    line-height: 70.4px;
    color: black;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;
